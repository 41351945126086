export const storage = {
    namespaced: true,
    state: () => ({
        snackbar: false,
        text: "",
        topicInfo: new Map(),
        typeInfo: new Map(),
        userInfo: null,
        waitingNum: 0
    }),
    mutations: {
        SET_SNACKBAR(state, value) {
            state.snackbar = value;
        },
        SET_TEXT(state, value) {
            state.text = value;
        },
        SET_TOPIC_INFO(state, value) {
            state.topicInfo = value;
        },
        SET_TYPE_INFO(state, value) {
            state.typeInfo = value;
        },
        SET_USER_INFO(state, value) {
            state.userInfo = value;
        },
        SET_WAITING_NUM(state, value) {
            state.waitingNum = value;
        }
    },
    getters: {
        snackbar(state) {
            return state.snackbar;
        },
        text(state) {
            return state.text;
        },
        topicInfo(state) {
            return state.topicInfo;
        },
        typeInfo(state) {
            return state.typeInfo;
        },
        userInfo(state) {
            return state.userInfo;
        },
        waitingNum(state) {
            return state.waitingNum;
        }
    },
    actions: {
        setSnackbar({ commit }, value) {
            commit('SET_SNACKBAR', value)
        },
        setText({ commit }, value) {
            commit('SET_TEXT', value)
        },
        setTopicInfo({ commit }, value) {
            commit('SET_TOPIC_INFO', value)
        },
        setTypeInfo({ commit }, value) {
            commit('SET_TYPE_INFO', value)
        },
        setUserInfo({ commit }, value) {
            commit('SET_USER_INFO', value)
        },
        setWaitingNum({ commit }, value) {
            commit('SET_WAITING_NUM', value)
        }
    }
};
