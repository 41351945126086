import { createApp, createVNode } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from "./store";
import router from "@/router/index";

// loadFonts()


const app = createApp({
    render: () => createVNode(App)
});

app.use(store)
app.use(vuetify)
app.use(router)
app.mount('#app')
