//import store from "@/state/store";

export default [
  {
    path: process.env.VUE_APP_PATH,
    name: "base",
    component: () => import("@/layouts/BaseLayout"),
    children: [
      { // '/' 으로 들어오면 '/home'으로 redirect 한다.
        path: process.env.VUE_APP_PATH,
        redirect: process.env.VUE_APP_HOME,
      },
      {
        path: process.env.VUE_APP_HOME,
        name: "home",
        component: () => import("@/pages/HomePage"),
      },
      {
        path: process.env.VUE_APP_AUTH,
        name: "auth",
        component: () => import("@/pages/AuthFeaturePage"),
      },
      {
        path: process.env.VUE_APP_BOARD,
        name: "board",
        component: () => import("@/pages/BoardPage"),
      },
      {
        path: process.env.VUE_APP_COMMENT,
        name: "comment",
        component: () => import("@/pages/CommentPage"),
      },
      {
        path: process.env.VUE_APP_DASHBOARD,
        name: "dashboard",
        component: () => import("@/pages/DashboardPage")
      },
      {
        path: process.env.VUE_APP_MY,
        name: "my",
        component: () => import("@/pages/MyPage")
      }
  
    ]
  },
  {
    path: process.env.VUE_APP_SNS_SIGNUP,
    name: "sns-signup",
    component: () => import("@/pages/SnsSignupPage"),
  },
  {
    path: process.env.VUE_APP_EMAIL_AUTHENTICATE,
    name: "email-authenticate",
    component: () => import("@/pages/EmailAuthenticatePage")
  },
  {
    path: process.env.VUE_APP_EMAIL_CHANGE_AUTHENTICATE,
    name: "email-change-authenticate",
    component: () => import("@/pages/EmailChangeAuthenticatePage")
  }
];
