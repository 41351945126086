<template>
    <v-app>
        <router-view />
    </v-app>
  </template>
  
  
  <script>
  // import { ref } from "vue";
  // import cryptoUtils from "@/utils/cryptoUtils"
  
  export default {
    name: "App",
    components: {
    },
    setup() {
    },
    data() {
      return {
      }
    }
  };
  </script>
  
  
  <style lang="scss">
</style>
  